import React from "react";

import Nav from "react-bootstrap/Nav";

import SiteStructure from "../components/siteStructure/SiteStructure";

import "./products.scss";

const Products = ({ children, subtitle = "subtitulo" }) => {
  // if (typeof window !== "undefined" && window.screen.width < 1000) {
  //   window.location =
  //     "http://www.blueskysa.com.ar/movile/04-botellas-burdeos.html";
  // }
  return (
    <>
      <div className="product-background">
        <SiteStructure>
          <div className="product-title">
            <h1 className="text-white d-flex justify-content-center title">
              CATÁLOGO
            </h1>
            <h3 className="text-white d-flex justify-content-center">
              {subtitle.toUpperCase()}
            </h3>
          </div>

          <div className="product justify-content-center">
            <div className="product__side-bar">
              <div className=" d-flex justify-content-center nav-title">
                LÍNEA
              </div>
              <Nav justify className="flex-column" variant="tabs">
                <Nav.Link href="/products/botellas_vino/burdeos">
                  Botella De Vino
                </Nav.Link>
                <Nav.Link href="/products/espumantes">Espumantes</Nav.Link>
                <Nav.Link href="/products/espirituosas">Espirituosas</Nav.Link>
                <Nav.Link href="/products/area-premium">Área Premium</Nav.Link>
                <Nav.Link href="/products/aceites">Aceites</Nav.Link>
                <Nav.Link href="/products/procesos-especiales">
                  Procesos Especiales
                </Nav.Link>
                <Nav.Link href="/products/cervezas">Cervezas</Nav.Link>
                <Nav.Link href="/products/kegs_cervezas">
                  Kegs Cervezas
                </Nav.Link>
                <Nav.Link href="/products/aguas-jugos">Aguas y Jugos</Nav.Link>
                <Nav.Link href="/products/tapas">Tapas</Nav.Link>
                <Nav.Link href="/products/barricas">Barricas de Orion</Nav.Link>
              </Nav>
            </div>
            <div className="product__children">{children}</div>
          </div>
        </SiteStructure>
      </div>
    </>
  );
};

export default Products;
